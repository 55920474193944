export const TEXTS = {
  ge: {
    home: "მთავარი",
    about: "ვინ ვართ ჩვენ?",
    services: "სერვისები",
    portfolio: "პორტფოლიო",
    contact: "საკონტაქტო",
    whoAreWe:
      " ვინ ვართ ჩვენ? - მარკეტერები, რომლებიც საქმის სუპერ პროფესიონალები ვართ.",
    ourVisionIs: "ჩვენი ხედვა ესეთია:",
    provideCustomers:
      " მიაწოდე მომხმარებელს სიახლე, კრეატივი, ხარისხი და ემოცია. ",
    whatIsYours: "რა არის შენი ",
    life: "ცხოვრების",
    main: "მთავარი",
    blueprint: "ბლუპრინტი?",
    onlyEnglish: "",
    findOut: "აღმოაჩინე ",
    more: "გაიგე მეტი",
    studio: "სტუდიური",
    order: "შეგვიკვეთე ახლავე",
    gift: "სასაჩუქრე იდეები",
    production: "ფროდაქშენი",
    socNet: "სოციალური ქსელების მართვა",
    polygraph: "პოლიგრაფია",
  },

  en: {
    home: "HOME",
    about: "WHO ARE WE? ",
    services: "SERVICES",
    portfolio: "PORTFOLIO",
    contact: "CONTACT",
    whoAreWe: "who are we? - Marketers who are super professionals.",
    ourVisionIs: "Our vision is:",
    provideCustomers:
      "Provide customers with news, creative, quality and emotion.",
    whatIsYours: "what is the",
    life: "main",
    blueprint: "blueprint",
    onlyEnglish: "in your life",
    youPlanYourVision:
      "You plan your vision, you plan your purpose that's we call your life blueprint",
    findOut: "Find out",
    more: "READ MORE",
    studio: "STUDIO",
    order: "order now",
    gift: "GIFT IDEAS",
    production: "PRODUCTION",
    socNet: "MANAGEMENT OF SOCIAL NETWORKS",
    polygraph: "POLYGRAPH",
  },
};
